import * as React from 'react'
import { IconButton } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'

export interface RefreshButtonProps {
  onClick: () => void;
}

export function RefreshButton(props: RefreshButtonProps): JSX.Element {
  return (
    <IconButton aria-label='Refresh' onClick={props.onClick}>
      <RefreshIcon />
    </IconButton>
  )
}
