import { List } from '@material-ui/core'
import * as React from 'react'
import Range from '../../service/Range'
import AssignedTable from './AssignedTable'
import TableGuest from './TableGuest'
import { GuestModelNew } from '../../model/NewGuestModel'
import { TableGuests } from '../../service/GeneralApi'

interface AssignedTableListProps {
  tableGuests: TableGuests;
  onItemClick: (guest: GuestModelNew) => void;
  numberOfTables: number;
}
const buildTableGuest = (guests: GuestModelNew[], onClick: (guest: GuestModelNew) => void): JSX.Element[] => {
  return guests.map((guest, idx) => <TableGuest key={idx} guest={guest} onClick={onClick} />)
}

const AssignedTableList: React.FunctionComponent<AssignedTableListProps> = (props): JSX.Element => {
  const numberOfTables = props.numberOfTables
  const tables = Range.to(numberOfTables).map((tableNumber) => (
    <AssignedTable
      key={tableNumber}
      tableNumber={tableNumber}
      guestsOnTable={buildTableGuest(
        props.tableGuests[tableNumber] || [],
        props.onItemClick,
      )}
    />
  ))
  return (
    <List
      style={{
        width: '100%',
        // maxWidth: 360,
        position: 'relative',
        overflow: 'auto',
        // maxHeight: 600,
      }}
    >
      {tables}
    </List>
  )
}

export default AssignedTableList
