import * as React from 'react'
import PaperPage from '../../../common/components/PaperPage'
import { Typography, Grid, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core'
import CursiveText from '../../../common/components/CursiveText'
import DirectionsIcon from "@material-ui/icons/Directions"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import NewTabLink from '../../../common/components/NewTabLink'
import { CeremonyContent } from '../../service/ContentApi'
import ContentService from '../../../common/ContentService'

export interface CeremonyProps {
  content?: CeremonyContent;
}

function Ceremony(props: CeremonyProps): JSX.Element {
  return (
    <PaperPage style={{ padding: 20, maxWidth: "90%", marginLeft: "auto", marginRight: "auto" }}>
      <Grid container>

        <div style={{ textAlign: 'center' }}>
          <CursiveText variant='h4' align='center'>
            Ceremony
          </CursiveText>
          <br />
          <Typography variant="body2" align="center">

            {ContentService.parseContent(props.content.text)}
          </Typography>
          <br />
          <Typography variant='body1' paragraph>
            {props.content.venue.name}
            <br />
            {props.content.venue.address}
            <br />
            {props.content.venue.eventTime}
            <br />
            <NewTabLink href={props.content.venue.directionLink}>
              Take me there!
              <DirectionsIcon />
            </NewTabLink>
          </Typography>
          <Grid container direction="column" alignItems="center">
            <Grid item style={{ maxWidth: "75%" }}>
              <ExpansionPanel>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                >
                  <Typography align="center">Parking options</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography>
                    {props.content.venue.parking.text}
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Grid>
            <Grid item>
              <img src={props.content.imageData.src}
                alt={props.content.imageData.alt} />
            </Grid>
          </Grid>
        </div>
      </Grid>
    </PaperPage>
  )
}

export default Ceremony
