import {
  createStyles, Modal, Theme, withStyles,
} from '@material-ui/core'
import * as React from 'react'
import { StyleRules } from '@material-ui/core/styles'

export interface BaseModalProps {
  show: boolean;
  onClose: () => void;
  classes: {
    paper: string;
  };
  children?: React.ReactNode;
}

const BaseModalInner: React.StatelessComponent<BaseModalProps> = (props: BaseModalProps): JSX.Element => (
  <Modal open={props.show} onEscapeKeyDown={props.onClose} onBackdropClick={props.onClose}>
    <div className={props.classes.paper}>{props.children}</div>
  </Modal>
)

const styles = ({ palette, spacing, shadows }: Theme): StyleRules => createStyles({
  paper: {
    position: 'absolute',
    // width: spacing.unit * 100,
    backgroundColor: palette.background.paper,
    boxShadow: shadows[5],
    padding: spacing() * 4,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
})
const BaseModal = withStyles(styles)(BaseModalInner)
export default BaseModal
