import * as React from 'react'
import { Grid } from '@material-ui/core'
import { SearchBar } from './SearchBar'
import { RefreshButton } from './RefreshButton'
import FilterSelect, { MenuItem } from './FilterSelect'

export interface TableControlsProps {
  onSearchTermChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onRefreshClick: () => void;
  onFilterChange: (newValue: string) => void;
  filters: MenuItem[];
}

export function TableControls(props: TableControlsProps): JSX.Element {
  return (
    <Grid container spacing={0} justify="flex-end">
      <Grid item>
        <SearchBar onChange={props.onSearchTermChange} />
      </Grid>
      <Grid item>
        <RefreshButton onClick={props.onRefreshClick} />
      </Grid>
      <Grid item>

        <FilterSelect
          items={props.filters}
          onChange={props.onFilterChange}
          displayEmpty={false}
        />
      </Grid>
    </Grid>
  )
}

