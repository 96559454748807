import * as React from 'react'
import CursiveText from '../../common/components/CursiveText'
import { Typography } from '@material-ui/core'

interface CountdownTimerProps {
  countDownDate: Date;
}

interface CountdownTimerState {
  days: number | null;
  hours: number;
  minutes: number;
  seconds: number;
  hasPassed: boolean;
}

export default class CountdownTimer extends React.Component<CountdownTimerProps, CountdownTimerState> {

  public constructor(props: CountdownTimerProps) {
    super(props)
    this.state = {
      days: null, hours: 0, minutes: 0, seconds: 0, hasPassed: false
    }
  }
  public componentDidMount(): void {
    this.updateCountdownTime(this.props.countDownDate.getTime())
    setInterval(() => {
      this.updateCountdownTime(this.props.countDownDate.getTime())
    }, 1000)
  }

  private updateCountdownTime(countDownDate: number): void {
    // Get today's date and time
    const now = new Date().getTime()

    // Find the distance between now and the count down date
    const distance = countDownDate - now

    // Time calculations for days, hours, minutes and seconds
    const days = Math.floor(distance / (1000 * 60 * 60 * 24))
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((distance % (1000 * 60)) / 1000)

    if (distance < 0) {
      this.setState({ hasPassed: true })
    } else {
      this.setState({ days, hours, minutes, seconds })
    }
  }

  public render(): JSX.Element {
    const { days, hours, minutes, seconds } = this.state
    let countdownValue: JSX.Element
    if (this.state.hasPassed) {
      countdownValue = (<CursiveText variant="h4" align="center">We are married!!!!</CursiveText>)
    } else {
      countdownValue = (<CursiveText variant="h4" align="center">{days}d {hours}h {minutes}m {seconds}s</CursiveText>)
    }
    return (
      <div>
        <CursiveText variant="h4" align="center">Time until we say I do!</CursiveText>
        <Typography variant="h4" align="center">&#128151;</Typography>
        {countdownValue}
      </div>

    )
  }
}
