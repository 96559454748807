export default class Display {
  public static booleanToSymbol(b: boolean): string {
    return b ? '✓' : '✘'
  }

  public static CHECK = Display.booleanToSymbol(true)
  public static CROSS = Display.booleanToSymbol(false)

  public static capitalize(s: string): string {
    return s.charAt(0).toUpperCase() + s.substring(1)
  }

  public static capitalizeName(s: string): string {
    return s.split(' ').map(this.capitalize).join(' ')
  }

  public static shorten(s: string, length = 10): string {
    return s.length > length ? `${s.slice(0, length)}...` : s
  }
}
