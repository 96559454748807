import { Button, Grid, Typography } from '@material-ui/core'
import * as React from 'react'
import BaseModal from '../../../common/components/BaseModal'
import { GuestPartyModelNew } from '../../model/NewGuestModel'
import Display from '../../service/Display'

export interface DeleteGuestModalProps {
  show: boolean;
  onClose: () => void;
  guestParty: GuestPartyModelNew;
  deleteGuestParty: (guest: GuestPartyModelNew) => Promise<void>;
}

function DeleteGuestModal(props: DeleteGuestModalProps): JSX.Element {
  const names = props.guestParty.guests.map(x => Display.capitalizeName(x.name)).join(', ')
  const text = `Are you sure you want to delete party of ${names}?`
  return (
    <BaseModal show={props.show} onClose={props.onClose}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant='body1' id='modal-title'>
            {text}
          </Typography>
        </Grid>
        <Grid container spacing={2} justify='flex-end'>
          <Grid item>
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                props.deleteGuestParty(props.guestParty).then(() => props.onClose())
              }}
            >
            Yes
            </Button>
          </Grid>
          <Grid item>
            <Button variant='contained' onClick={props.onClose}>
            No
            </Button>
          </Grid>
        </Grid>

      </Grid>
    </BaseModal>
  )
}
export default DeleteGuestModal
