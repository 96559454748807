import { List } from '@material-ui/core'
import * as React from 'react'
import TableGuest from './TableGuest'
import { GuestModelNew } from '../../model/NewGuestModel'

interface UnassignedTableListProps {
  unassignedGuests: GuestModelNew[];
  onItemClick: (guest: GuestModelNew) => void;
}

const UnassignedTableList: React.FunctionComponent<UnassignedTableListProps> = (props): JSX.Element => {
  const guests = props.unassignedGuests.map((guest, idx) => (
    <TableGuest key={idx} guest={guest} onClick={props.onItemClick} />
  ))
  return <List>{guests}</List>
}

export default UnassignedTableList
