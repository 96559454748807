import {
  Button,
  CircularProgress,
  createStyles,
  Grid,
  TextField,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import * as React from 'react'
import CursiveText from '../../common/components/CursiveText'
import PaperPage from '../../common/components/PaperPage'
import {StyleRules} from '@material-ui/core/styles'
import {Link} from 'react-router-dom'
import AppContext from '../../settings/model/Context'

export interface LoginLandingProps extends WithStyles<typeof styles> {
  guestLogin: (name: GuestName) => Promise<void>;
  brideGroomLogin: (user: string, pass: string) => Promise<void>;
  isBrideGroom: boolean;
}

export interface GuestName {
  firstName: string;
  lastName: string;
}

interface LoginLandingState {
  name: string;
  passOrLast: string;
  error: boolean;
  helperText: string;
  isLoading: boolean;
}

class LoginLandingInner extends React.Component<LoginLandingProps, LoginLandingState> {
  constructor(props: LoginLandingProps) {
    super(props)
    this.state = {
      name: '',
      passOrLast: '',
      error: false,
      helperText: '',
      isLoading: false,
    }
  }

  static contextType = AppContext
  context!: React.ContextType<typeof AppContext>

  public handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const target = e.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    switch (target.id) {
      case 'name':
        this.setState({name: value as string})
        break
      case 'pass':
        this.setState({passOrLast: value as string})
        break
    }
  }

  public performLogin = async (e: React.MouseEvent): Promise<void> => {
    e.preventDefault()
    this.setState({isLoading: true})
    const {isBrideGroom} = this.props
    const {name: nameOrCode, passOrLast: pass} = this.state
    try {
      if (!isBrideGroom) {
        await this.props.guestLogin({firstName: nameOrCode, lastName: pass})
      } else {
        await this.props.brideGroomLogin(nameOrCode, pass)
      }
    } catch (e) {
      this.setState({
        error: true,
        helperText: 'Oops, maybe try that one again...',
        passOrLast: isBrideGroom ? '' : pass,
      })
    } finally {
      this.setState({isLoading: false})
    }
  }

  public render(): React.ReactNode {
    const {isBrideGroom} = this.props
    let passOrLast: JSX.Element
    if (isBrideGroom) {
      passOrLast = (<Grid item>
        <TextField
          id='pass'
          label='Password'
          required
          error={this.state.error}
          type='password'
          value={this.state.passOrLast}
          onChange={this.handleChange}
          margin='normal'
          helperText={this.state.helperText}
        />
      </Grid>)
    } else {
      passOrLast = (<Grid item>
        <TextField
          id='pass'
          label='Last Name'
          required
          error={this.state.error}
          value={this.state.passOrLast}
          onChange={this.handleChange}
          margin='normal'
          helperText={this.state.helperText}
        />
      </Grid>)
    }
    const buttonText = isBrideGroom ? 'Guest' : 'Bride/Groom'
    const buttonLink = isBrideGroom ? '/' : '/admin'
    const pageData = this.context.settings.loginPageData
    return (
      <Grid container spacing={2} justify='center' alignItems='center'>
        <PaperPage >
          <Grid item container direction='row' justify='flex-end'>
            <Grid>
              <Link to={buttonLink}>
                <Button size='small'>To {buttonText} login</Button>
              </Link>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <img src={pageData.header.src} alt={pageData.header.alt} className="front-image"></img>
            <img src={pageData.image.src} alt={pageData.image.alt} className="front-image"></img>
          </Grid>
          <Grid item xs={12}>
            <form noValidate autoComplete='off' className={this.props.classes.root}>
              <Grid container direction='column' alignItems='center'>
                <Grid item>
                  <CursiveText variant='h4' align='center'>
                    To get started enter the full name of one person on your invitation
                  </CursiveText>
                </Grid>
                <Grid item>
                  <TextField
                    id='name'
                    label={this.props.isBrideGroom ? 'Name' : 'First Name'}
                    required
                    error={this.state.error}
                    value={this.state.name}
                    onChange={this.handleChange}
                    margin='normal'
                  />
                </Grid>
                {passOrLast}
                <Grid item style={{textAlign: 'center'}}>
                  {this.state.isLoading ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      type='submit'
                      variant='contained'
                      color='primary'
                      onClick={this.performLogin}
                      className={this.props.classes.button}
                    >
                        Enter
                    </Button>
                  )}
                </Grid>
              </Grid>
            </form>
          </Grid>
        </PaperPage>
      </Grid>
    )
  }
}

const styles = (theme: Theme): StyleRules => createStyles({
  root: {
    padding: theme.spacing() * 2,
  },
  button: {
    margin: theme.spacing(),
  },
  heading: {
    margin: theme.spacing() * 2,
  },
})

const LoginLanding = withStyles(styles)(LoginLandingInner)

export default LoginLanding
