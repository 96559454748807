import {Grid, Typography} from '@material-ui/core'
import * as React from 'react'
import BaseModal from '../../../common/components/BaseModal'
import GuestPartyModalForm from './GuestPartyModalForm'
import {GuestPartyModelNew} from '../../model/NewGuestModel'
import {EmptyRecord} from '../../../common/EmptyRecord'

export interface GuestPartyModalProps {
  show: boolean;
  onClose: () => void;
  guestParty: GuestPartyModelNew;
  addGuestParty: (guest: GuestPartyModelNew) => Promise<void>;
  updateGuestParty: (guest: GuestPartyModelNew) => Promise<void>;
}

class GuestPartyModal extends React.Component<GuestPartyModalProps, EmptyRecord> {
  public render(): JSX.Element {
    return (
      <BaseModal show={this.props.show} onClose={this.props.onClose}>
        <Grid container={true} spacing={3}>
          <Grid item={true} xs={12}>
            <Typography variant='h6'>Wedding Guest</Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <GuestPartyModalForm
              onClose={this.props.onClose}
              guest={this.props.guestParty}
              addGuest={this.props.addGuestParty}
              updateGuest={this.props.updateGuestParty}
            />
          </Grid>
        </Grid>
      </BaseModal>
    )
  }
}

export default GuestPartyModal
