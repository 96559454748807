import { Grid, CircularProgress, Typography } from '@material-ui/core'
import * as React from 'react'
import { KeyValueCard } from './KeyValueCard'
import AuthenticatedApi from '../../service/GeneralApi'
import GuestStatistic from '../../model/GuestStatistic'

export interface GuestListSummaryProps {
  token: string;
}

interface State {
  isLoading: boolean;
  stats: GuestStatistic[];
}


export default class GuestListSummary extends React.Component<GuestListSummaryProps, State> {
  constructor(props: GuestListSummaryProps) {
    super(props)
    this.state = {
      isLoading: true,
      stats: []
    }
  }

  componentDidMount(): void {
    AuthenticatedApi.getGuestStats(this.props.token)
      .then(x => this.setState({ stats: x }))
      .finally(() => this.setState({ isLoading: false }))
  }


  public render(): JSX.Element {
    if (this.state.isLoading) {
      return (<React.Fragment>
        <CircularProgress />
        <Typography align='center' variant='h4'>
          Hold tight we are crunching some numbers...
        </Typography>
      </React.Fragment>)
    }
    return (
      <Grid container={true} spacing={1} style={{padding: '10px'}}>
        {
          this.state.stats.map((x, idx) => (
            <Grid item key={idx}>
              <KeyValueCard key={idx} title={x.name} value={x.number} />
            </Grid>
          ))
        }
      </Grid >
    )
  }
}
