import { createStyles, TableCell, Theme, withStyles } from '@material-ui/core'
import { TableCellProps } from '@material-ui/core/TableCell'
import * as React from 'react'
import { StyleRules } from '@material-ui/core/styles'

const customCellStyles = ({ palette }: Theme): StyleRules =>
  createStyles({
    head: {
      backgroundColor: palette.grey['400'],
      color: palette.common.black,
      fontSize: 14,
    },
  })

const CustomTableCell = withStyles(customCellStyles)(
  ({ children, color, classes }: TableCellProps): React.ReactElement => (
    <TableCell color={color} classes={classes}>{children}</TableCell>
  ),
)

export default CustomTableCell
