import * as React from 'react'

export default class ContentService {
  /**
   * Parse a string and construct a list of nodes adding line breaks for new lines.
   */
  static parseContent(content?: string): React.ReactNode[] {
    if (!content) {
      return []
    }
    const result = []
    const lineBreak: JSX.Element = (<br />)
    content.split("\n").forEach(x => {
      result.push(x)
      result.push(lineBreak)
    })
    return result.map((x, idx) => <React.Fragment key={idx}>{x}</React.Fragment>)
  }
}
