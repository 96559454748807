import * as React from 'react'
import InviteTableWrapper from './invite/InviteTableWrapper'
import GuestPartyModalWrapper from './guestList/GuestPartyModalWrapper'
import GuestListSummary from './summary/GuestListSummary'
import GuestTableContent from './tables/GuestTableContent'
import { GuestPartyModelNew } from '../model/NewGuestModel'
import ReportsContent from './reports/ReportsContent'

export interface GuestListTabContentProps {
  guests: GuestPartyModelNew[];
  tabValue: number;
  fetchGuests: () => void;
  token: string;
}

export function GuestListTabContent(props: GuestListTabContentProps): JSX.Element {
  return (
    <React.Fragment>
      {props.tabValue === 0 && <GuestListSummary token={props.token} />}
      {props.tabValue === 1 && (
        <GuestPartyModalWrapper
          guests={props.guests}
          fetchGuests={props.fetchGuests}
          token={props.token}
        />
      )}
      {props.tabValue === 2 && (
        <InviteTableWrapper
          guestParties={props.guests}
          token={props.token}
          fetchGuests={props.fetchGuests}
        />
      )}
      {props.tabValue === 3 && (
        <GuestTableContent/>
      )}
      {props.tabValue === 4 && (
        <ReportsContent/>
      )}
    </React.Fragment>
  )
}
