import * as React from 'react'
import InviteModal from './InviteModal'
import InviteTable from './InviteTable'
import {GuestPartyModelNew, EMPTY_PARTY} from '../../model/NewGuestModel'

export interface InviteTableWrapperProps {
  guestParties: GuestPartyModelNew[];
  token: string;
  fetchGuests: () => void;
}

export interface InviteTableWrapperState {
  showModal: boolean;
  selected: GuestPartyModelNew | null;
}

export default class InviteTableWrapper extends React.Component<
InviteTableWrapperProps,
InviteTableWrapperState
> {
  constructor(props: InviteTableWrapperProps) {
    super(props)

    this.state = {showModal: false, selected: null}
  }

  public hideModal = (): void => {
    this.setState({showModal: false})
    this.props.fetchGuests()
  }

  public showModal = (): void => {
    this.setState({showModal: true})
  }

  public setSelectedGuest = (guest: GuestPartyModelNew): void => {
    this.setState({selected: guest})
  }

  public render(): JSX.Element {
    return (
      <React.Fragment>
        <InviteTable
          guestParties={this.props.guestParties}
          onRefresh={this.props.fetchGuests}
          onItemSelect={(guest) => {
            this.showModal()
            this.setSelectedGuest(guest)
          }}
        />
        <InviteModal
          show={this.state.showModal}
          onClose={this.hideModal}
          guestParty={this.state.selected || EMPTY_PARTY()}
        />
      </React.Fragment>
    )
  }
}
