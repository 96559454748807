export interface GuestPartyModelNew {
  id: number;
  side: string;
  responded: boolean;
  guests: GuestModelNew[];
  address?: Address;
}

export interface GuestModelNew {
  id: number;
  name: string;
  attending?: boolean;
  dietary?: string;
  tableNumber?: number;
  under18: boolean;
  // TODO: Is this worth becoming an enum
  ageGroup?: string;
  seatNumber?: number;
}

export interface Address {
  id: number;
  city: string;
  streetAddress: string;
  state: string;
  postCode: string;
}

export const EMPTY_GUEST = (): GuestModelNew =>{
  return {
    id: 0,
    name: '',
    attending: false,
    dietary: '',
    under18: false,
  }
}

export const EMPTY_PARTY= (): GuestPartyModelNew => {
  return {
    id: 0,
    side: '',
    responded: false,
    guests: [EMPTY_GUEST()]
  }
}

export const partialToWholeParty = (partial: Partial<GuestPartyModelNew>): GuestPartyModelNew => ({
  id: partial.id || EMPTY_PARTY().id,
  side: partial.side || EMPTY_PARTY().side,
  responded: partial.responded || EMPTY_PARTY().responded,
  guests: partial.guests ? partial.guests.map(x => partialToWholeGuest(x)) : EMPTY_PARTY().guests,
  address: partial.address ? partialToWholeAddress(partial.address): partial.address
})

const partialToWholeGuest = (partial: Partial<GuestModelNew>): GuestModelNew => {
  return {
    id: partial.id || EMPTY_GUEST().id,
    name: partial.name || EMPTY_GUEST().name,
    attending: partial.attending || EMPTY_GUEST().attending,
    dietary: partial.dietary || EMPTY_GUEST().dietary,
    under18: partial.under18 || EMPTY_GUEST().under18
  }
}

export const partialToWholeAddress = (partial: Partial<Address>): Address => {
  return {
    id: partial.id || 0,
    city: partial.city || '',
    streetAddress: partial.streetAddress || '',
    state: partial.state || '',
    postCode: partial.postCode || ''
  }
}