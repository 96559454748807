import {ImageData} from "../../guestView/service/ContentApi"

export default interface SettingsModel {
  names: BrideGroomName;
  weddingTime: string;
  loginPageData: LoginPageData;
  separateCeremonyAndReception: boolean;
}

export interface BrideGroomName {
  groom: string;
  bride: string;
}

export interface LoginPageData {
  header: ImageData;
  image: ImageData;
  alertText: string;
}

const EMPTY_IMAGE_DATA: ImageData = {
  src: '',
  alt: ''
}

const EMPTY_SETTINGS: SettingsModel = {
  names: {
    bride: '',
    groom: ''
  },
  weddingTime: "",
  loginPageData: {
    header: EMPTY_IMAGE_DATA,
    image: EMPTY_IMAGE_DATA,
    alertText: ""
  },
  separateCeremonyAndReception: false
}

export {EMPTY_SETTINGS}
