import axios from 'axios'
import WeditApi from '../../common/WeditApi'

class ContentApi {
  public static URL = `${WeditApi.URL}/content`

  public static async getContent(): Promise<Content> {
    return await axios.get(`${this.URL}/${WeditApi.CLIENT_UUID}`).then(x => x.data)
  }
}

export interface Content {
  memories: MemoriesContent;
  story: StoryContent;
  ceremony: CeremonyContent;
  reception: ReceptionContent;
}

export interface StoryContent {
  text: string;
  imageData: ImageData;
  quoteText: string;
}

export interface CeremonyContent {
  text: string;
  venue: Venue;
  imageData: ImageData;
}

export interface ReceptionContent {
  text: string;
  venue: Venue;
  imageData: ImageData;
}

export interface Venue {
  name: string;
  address: string;
  eventTime: string;
  directionLink: string;
  parking: ParkingOptions;
}

export interface ParkingOptions {
  text: string;
  locations: ParkingLocation[];
}

export interface ParkingLocation {
  link: string;
  address: string;
}

export interface ImageData {
  src: string;
  alt: string;
}

export interface MemoriesContent {
  images: ImageData[];
}

export default ContentApi
