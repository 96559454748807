import {
  Table, TableBody, TableHead, TablePagination, TableRow, Typography,
} from '@material-ui/core'
import * as React from 'react'
import CustomTableCell from './CustomTableCell'

export interface PaginatedTableProps<T> {
  headers: React.ReactNode[];
  rowData: T[];
  rowRender: (data: T, idx: number) => React.ReactElement;
}

interface PaginatedTableState {
  page: number;
  rowsPerPage: number;
}

export default class PaginatedTable<T> extends React.Component<PaginatedTableProps<T>, PaginatedTableState> {
  constructor(props: PaginatedTableProps<T>) {
    super(props)
    this.state = {
      page: 0,
      rowsPerPage: 25,
    }
  }

  public onChangePage = (_: unknown, page: number): void => {
    this.setState({page})
  }

  public render(): JSX.Element {
    const headerCells = this.props.headers.map((x, idx) => (
      <CustomTableCell key={idx}>{x}</CustomTableCell>
    ))
    const startIndex = this.state.page * this.state.rowsPerPage

    let data
    if (this.props.rowData.length === 0) {
      data = (<Typography>No items match your search...</Typography>)
    } else {
      data = this.props.rowData
        .slice(startIndex, startIndex + this.state.rowsPerPage)
        .map(this.props.rowRender)
    }

    return (
      <React.Fragment>
        <TablePagination
          component='div'
          count={this.props.rowData.length}
          onChangePage={this.onChangePage}
          page={this.state.page}
          rowsPerPage={this.state.rowsPerPage}
          onChangeRowsPerPage={(e) => this.setState({rowsPerPage: Number(e.target.value)})}
        />
        <Table>
          <TableHead>
            <TableRow>{headerCells}</TableRow>
          </TableHead>
          <TableBody>{data}</TableBody>
        </Table>
        <TablePagination
          component='div'
          count={this.props.rowData.length}
          onChangePage={this.onChangePage}
          page={this.state.page}
          rowsPerPage={this.state.rowsPerPage}
          onChangeRowsPerPage={(e) => this.setState({rowsPerPage: Number(e.target.value)})}
        />
      </React.Fragment>
    )
  }
}
