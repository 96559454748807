import {Typography, Button, Paper} from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import * as React from 'react'
import {EmptyRecord} from '../EmptyRecord'

const ErrorPage: React.FunctionComponent<EmptyRecord> = (): JSX.Element => (
  <Paper style={{padding: 'inherit'}}>
    <Typography variant='h3' align='center'>
      Something went wrong <span role="img" aria-label="disappointed">😓</span>
      <br />
      <Button variant='contained' onClick={() => window.location.reload()} startIcon={<RefreshIcon />}> Refresh</Button>
    </Typography>
    <Typography variant='h4' align='center'>
      Please refresh the page and we can try again.
    </Typography>
  </Paper >
)

export default ErrorPage
