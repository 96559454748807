import * as React from 'react'
import {GuestModelNew} from '../../model/NewGuestModel'
import PaperPage from '../../../common/components/PaperPage'
import AppContext from '../../../settings/model/Context'
import AuthenticatedApi, {TableGuests, TableUpdateRequest} from '../../service/GeneralApi'
import {CircularProgress, Grid, List, ListSubheader, Typography} from '@material-ui/core'
import UnassignedTableList from './UnassignedTableList'
import AssignTableModal from './AssignTableModal'
import AssignedTableList from './AssignedTableList'
import ErrorSnackbar from '../../../common/components/ErrorSnackbar'
import {EmptyRecord} from '../../../common/EmptyRecord'

interface State {
  showModal: boolean;
  selectedGuest?: GuestModelNew;
  tableGuests?: TableGuests;
  isLoading: boolean;
  showError: boolean;
}

export default class GuestTableContent extends React.Component<EmptyRecord, State> {
  constructor(props: EmptyRecord) {
    super(props)
    this.state = {
      showModal: false,
      isLoading: true,
      showError: false
    }
  }

  static contextType = AppContext
  context!: React.ContextType<typeof AppContext>

  componentDidMount(): void {
    this.fetchTableGuests()
  }

  public closeModal = (): void => {
    this.setState({showModal: false})
  }

  public openModal = (): void => {
    this.setState({showModal: true})
  }

  public fetchTableGuests = (): void => {
    AuthenticatedApi.getTableGuests(this.context.token)
      .then(x => this.setState({tableGuests: x}))
      .finally(() => this.setState({isLoading: false}))
  }

  public updateGuest = async (update: TableUpdateRequest): Promise<void> => {
    this.closeModal()
    this.setState({isLoading: true})
    try {
      const tableGuests = await AuthenticatedApi.updateGuestTableNumber(update, this.context.token)
      this.setState({tableGuests})
    } catch (e) {
      this.setState({showError: true})
    } finally {
      this.setState({isLoading: false})
    }
  }

  public updateTable = async (tableNumber: number, seatNumber: number): Promise<void> => {
    const guest = this.state.selectedGuest
    await this.updateGuest({tableNumber: tableNumber, guestId: guest.id, seatNumber: seatNumber})
  }

  public listItemClick = (clickedGuest: GuestModelNew): void => {
    this.setState({selectedGuest: clickedGuest})
    this.openModal()
  }

  public render(): JSX.Element {
    if (this.state.isLoading) {
      return (
        <CircularProgress />
      )
    }

    if (Object.keys(this.state.tableGuests).length === 0) {
      return (<PaperPage><Typography>Looks like no guests have responded yet. Check back in a little while.</Typography></PaperPage>)
    }

    const guestWithNoTable = this.state.tableGuests[-1]
    const numberOfTables = 20
    return (
      <React.Fragment>
        <Grid container>
          <Grid item xs={6}>
            <PaperPage>
              <List>
                <ListSubheader>Guests Without A Table</ListSubheader>
                <UnassignedTableList
                  unassignedGuests={guestWithNoTable}
                  onItemClick={this.listItemClick}
                />
              </List>
            </PaperPage>
          </Grid>
          <Grid item xs={6}>
            <PaperPage>
              <AssignedTableList
                tableGuests={this.state.tableGuests}
                onItemClick={this.listItemClick}
                numberOfTables={numberOfTables}
              />
            </PaperPage>
          </Grid>
        </Grid>
        <AssignTableModal
          show={this.state.showModal}
          close={this.closeModal}
          maxNumber={numberOfTables}
          onSubmit={this.updateTable}
          guest={this.state.selectedGuest}
        />
        <ErrorSnackbar message="Failed to update guest" open={this.state.showError} onClose={() => this.setState({showError: false})} />
      </React.Fragment>
    )
  }
}
