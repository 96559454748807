import * as React from 'react'
import PaperPage from '../../../common/components/PaperPage'
import { Typography, Grid } from '@material-ui/core'
import CursiveText from '../../../common/components/CursiveText'
import ContentService from '../../../common/ContentService'
import { StoryContent } from '../../service/ContentApi'

export interface OurStoryProps {
  content?: StoryContent;
}

function OurStory(props: OurStoryProps): JSX.Element {
  return (
    <PaperPage style={{ padding: 20, maxWidth: "80%", marginLeft: "auto", marginRight: "auto" }}>
      <CursiveText variant='h4' align='center'>
        Our Love Story
      </CursiveText>
      <br />
      <Typography variant="body2" align="center" style={{ paddingLeft: '10px', paddingRight: '10px' }}>
        {ContentService.parseContent(props.content.text)}
      </Typography >
      <br /><br />
      <CursiveText align="center" variant="h4">
        {props.content.quoteText}
      </CursiveText>
      <Grid container justify="center">

        <img src={props.content.imageData.src} alt={props.content.imageData.alt} />
      </Grid>
    </PaperPage >
  )
}

export default OurStory

// #edf0e06b