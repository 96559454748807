import * as React from 'react'
import { Typography } from '@material-ui/core'

interface MultiLineTextProps {
  textLines: string[];
}

const MultiLineText: React.FunctionComponent<MultiLineTextProps> = (props): JSX.Element => (
  <React.Fragment>
    {props.textLines.map((x, idx) => (
      <Typography variant='body2' key={idx}>{x}</Typography>
    ))}
  </React.Fragment>
)

export default MultiLineText
