import {TableCell, Typography} from '@material-ui/core'
import * as React from 'react'

function TableTextCell(props: React.PropsWithChildren<Record<string, unknown>>): React.ReactElement {
  return (
    <TableCell>
      <Typography variant='body2'>{props.children}</Typography>
    </TableCell>
  )
}

export default TableTextCell
