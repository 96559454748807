import * as React from 'react'
import PaginatedTable from '../../../common/components/PaginatedTable'
import PaperPage from '../../../common/components/PaperPage'
import {InviteTableRow} from './InviteTableRow'
import {TableControls} from '../../../common/components/TableControls'
import {GuestPartyModelNew} from '../../model/NewGuestModel'

export interface InviteTableProps {
  guestParties: GuestPartyModelNew[];
  onItemSelect: (guest: GuestPartyModelNew) => void;
  onRefresh: () => void;
}

export interface InviteTableState {
  currentFilter: (guest: GuestPartyModelNew) => boolean;
  searchTerm: string;
}

interface Filters {
  [key: string]: (guest: GuestPartyModelNew) => boolean;
}

const filters: Filters = {
  '': () => true,
  'withDietary': (party) => {
    return party.guests.map(x => x.dietary).some(x => x && x.length > 0)
  },
  'hasResponded': (party) => {
    return party.responded
  },
  'hasNotResponded': (party) => {
    return !party.responded
  },
  'isNotAttending': (party) => {
    return party.responded && party.guests.map(x => x.attending).some(x => !x)
  }
}

export default class InviteTable extends React.Component<InviteTableProps, InviteTableState> {
  constructor(props: InviteTableProps) {
    super(props)
    this.state = {
      currentFilter: filters[''],
      searchTerm: ''
    }
  }

  public filterChange = (value: string): void => {
    this.setState({currentFilter: filters[value]})
  }

  public search = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({searchTerm: e.target.value})
  }

  public render(): React.ReactNode {
    const filteredGuests = this.props.guestParties.filter(this.state.currentFilter)
    const guestsToShow = filteredGuests.filter(guest => guest.guests.some(guest => guest.name.includes(this.state.searchTerm)))

    return (
      <React.Fragment>
        <TableControls filters={[
          {text: 'No Filter', value: ''},
          {text: 'With Dietary Requirements', value: 'withDietary'},
          {text: 'Has Responded', value: 'hasResponded'},
          {text: 'Has Not Responded', value: 'hasNotResponded'},
          {text: 'Is Not Attending', value: 'isNotAttending'}
        ]} onFilterChange={this.filterChange} onRefreshClick={this.props.onRefresh} onSearchTermChange={this.search} />
        <PaperPage>
          <PaginatedTable
            headers={['Name', 'Responded', 'Attending', 'Dietary Requirements']}
            rowData={guestsToShow}
            rowRender={(guest) => (
              <InviteTableRow key={guest.id} guestParty={guest} onClick={this.props.onItemSelect} />
            )}
          />
        </PaperPage>
      </React.Fragment>
    )
  }
}
