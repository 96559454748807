import { ListSubheader, Divider } from '@material-ui/core'
import * as React from 'react'

interface AssignedTableProps {
  tableNumber: number;
  guestsOnTable: React.ReactNode;
}

const AssignedTable: React.FunctionComponent<AssignedTableProps> = (props): JSX.Element => {
  const header = props.tableNumber === 0 ? 'Bridal Table' : `Table Number ${props.tableNumber}`
  return (
    <React.Fragment>
      <li style={{ backgroundColor: 'inherit' }}>
        <ul
          style={{
            backgroundColor: 'inherit',
            padding: 0,
          }}
        >
          <ListSubheader>{header}</ListSubheader>
          {props.guestsOnTable}
        </ul>
      </li>
      <Divider />
    </React.Fragment>
  )
}

export default AssignedTable
