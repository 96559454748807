import * as React from 'react'
import {Grid} from '@material-ui/core'
import WeditApi from '../../../common/WeditApi'
import PaperPage from '../../../common/components/PaperPage'
import {ReportElement} from './ReportElement'
import {EmptyRecord} from '../../../common/EmptyRecord'

export default class ReportsContent extends React.Component<EmptyRecord, EmptyRecord> {
  public render(): JSX.Element {
    return (
      <PaperPage>
        <Grid container spacing={2} justify="center">
          <ReportElement
            buttonText="Report for Name Tags"
            infoText="This will generate a report with all attending guests on a new line, perfect for name tags or wedding favors"
            reportUrl={`${WeditApi.URL}/generate/guest-name-list.csv?attending=true`}
            reportFileName="guest_names.csv"
          />
          <ReportElement
            buttonText="Report for Invitations"
            infoText="This will generate a report with names of all guests in a party along with their address. You may want to tweak these to suit your preference but should give the perfect start for invitations."
            reportUrl={`${WeditApi.URL}/generate/invitation-list.csv`}
            reportFileName="guest_names.csv"
          />
          <ReportElement
            buttonText="Seating Plan"
            infoText="This will generate a seating plan that you can provide to your reception venue based on your table plan."
            reportUrl={`${WeditApi.URL}/generate/seating-plan.csv`}
            reportFileName="seating_plan.csv"
          />
        </Grid>
      </PaperPage>
    )
  }
}
