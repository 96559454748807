import {
  createMuiTheme,
  createStyles,
  CssBaseline,
  MuiThemeProvider,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import * as React from 'react'
import AppContent from './AppContent'
import {StyleRules} from '@material-ui/core/styles'


class AppInner extends React.Component<WithStyles<typeof styles>, Record<string, never>> {

  public theme: Theme = createMuiTheme({
    palette: {
      secondary: {
        light: '#8e8e8e',
        main: '#616161',
        dark: '#373737',
        contrastText: '#ffffff',
      },
      primary: {
        light: '#ffff6b',
        main: '#e7d587',
        dark: '#c6a700',
        contrastText: '#000000',
      },
      background: {
        paper: '#edf0e0'
      }
    },
  })

  public render(): JSX.Element {
    return (
      <MuiThemeProvider theme={this.theme}>
        <CssBaseline />
        <div className="background" style={{minHeight: '100%'}}>
          <div className={this.props.classes.root}>
            <AppContent />
          </div>
        </div>
      </MuiThemeProvider>
    )
  }
}

const styles = (): StyleRules => createStyles({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%',
  },
})
const App = withStyles(styles)(AppInner)
export default App
