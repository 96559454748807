import {Table, TableRow, TableHead, TableBody, Checkbox, Button, Grid, CircularProgress} from '@material-ui/core'
import * as React from 'react'
import BaseModal from '../../../common/components/BaseModal'
import TableTextCell from '../TableTextCell'
import Display from '../../service/Display'
import CustomTableCell from '../../../common/components/CustomTableCell'
import {GuestPartyModelNew, GuestModelNew} from '../../model/NewGuestModel'
import GuestApi, {Attendance} from '../../service/GuestApi'
import AppContext from '../../../settings/model/Context'

interface InviteModalProps {
  guestParty: GuestPartyModelNew;
  show: boolean;
  onClose: () => void;
}

interface InviteModalState {
  attendance: Attendance;
  isSubmitting: boolean;
}


function fill(guests: GuestModelNew[]): Attendance {
  return guests.reduce((acc: Attendance, guest: GuestModelNew): Attendance => {
    acc[guest.id] = guest.attending || false
    return acc
  }, {})
}

export default class InviteModal extends React.Component<InviteModalProps, InviteModalState> {
  constructor(props: InviteModalProps) {
    super(props)
    this.state = {
      attendance: fill(props.guestParty.guests),
      isSubmitting: false
    }
  }

  static contextType = AppContext
  context!: React.ContextType<typeof AppContext>

  componentDidUpdate(prevProps: InviteModalProps, _: InviteModalState): void {
    const prevGuestParty = prevProps.guestParty || {id: 0}
    if (this.props.guestParty.id !== prevGuestParty.id) {
      this.setState({attendance: fill(this.props.guestParty.guests)})
    }
  }

  onChange = (id: number) => (event: React.ChangeEvent<HTMLInputElement>): void => {
    const attendance = this.state.attendance
    attendance[id] = event.target.checked
    this.setState({attendance: attendance})
  }

  submitUpdate = async (): Promise<void> => {
    try {
      this.setState({isSubmitting: true})
      GuestApi.putGuestPartyAttendance(this.props.guestParty.id, this.state, this.context.token)
    } finally {
      this.setState({isSubmitting: false})
      this.props.onClose()
    }
  }

  public render(): JSX.Element {
    const {guestParty, show, onClose} = this.props
    const rows = guestParty.guests.map((guest, idx) => {
      return (
        <TableRow key={idx}>
          <TableTextCell>{Display.capitalizeName(guest.name)}</TableTextCell>
          <TableTextCell>
            <Checkbox checked={this.state.attendance[guest.id]} onChange={this.onChange(guest.id)} />
          </TableTextCell>
          <TableTextCell>{guest.dietary ? guest.dietary : ''}</TableTextCell>
        </TableRow>
      )
    })
    return (
      <BaseModal show={show} onClose={onClose}>
        <Table>
          <TableHead>
            <TableRow>
              <CustomTableCell>Name</CustomTableCell>
              <CustomTableCell>Attending</CustomTableCell>
              <CustomTableCell>Dietary</CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows}
          </TableBody>
        </Table>
        <Grid container justify="flex-end">
          {this.state.isSubmitting ?
            (<CircularProgress />) :
            (<Button variant="contained" style={{marginTop: "10px"}} onClick={this.submitUpdate}>Update</Button>)
          }
        </Grid>
      </BaseModal>
    )
  }
}

