import { Card, CardContent, Typography } from '@material-ui/core'
import * as React from 'react'

export interface KeyValueCardProps {
  title: string;
  value: number;
}

export function KeyValueCard(props: KeyValueCardProps): JSX.Element {
  return (
    <Card>
      <CardContent>
        <Typography variant='h4' color='textSecondary'>{props.title}</Typography>
        <Typography variant='h2' align='center' color='textSecondary'>
          {props.value}
        </Typography>
      </CardContent>
    </Card>
  )
}
