import {
  Button,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import * as React from 'react'
import {StringMap} from '../../../organizerView/model/MapUtilTypes'
import {GuestPartyResponseModelNew, GuestResponseModelNew} from '../../../organizerView/service/GuestResponseApi'
import CursiveText from '../../../common/components/CursiveText'
import GuestAttendanceForm, {AttendanceMap} from './GuestAttendanceForm'
import {GuestPartyModelNew, GuestModelNew} from '../../../organizerView/model/NewGuestModel'
import {BrideGroomName} from '../../../settings/model/SettingsModel'
import Display from '../../../organizerView/service/Display'
import {EmptyRecord} from '../../../common/EmptyRecord'

export interface GuestAnswerFormProps {
  name: string;
  guestParty: GuestPartyModelNew;
  onSubmit: (response: GuestPartyResponseModelNew) => Promise<void>;
  brideGroom: BrideGroomName;
}

interface GuestAnswerFormState {
  willAttend: string;
  hasDietary: string;
  dietary: StringMap;
  attendingGuests: AttendanceMap;
  isSubmitting: boolean;
}

function fill(guests: GuestModelNew[], fillValue: boolean): AttendanceMap {
  return guests.reduce((acc: AttendanceMap, guest: GuestModelNew): AttendanceMap => {
    const ageGroup = guest.under18 ? "" : "ADULT"
    acc[guest.name] = {attending: fillValue, ageGroup: ageGroup}
    return acc
  }, {})
}

function fillEmpty(guests: GuestModelNew[], fillValue: boolean): AttendanceMap {
  return guests.reduce((acc: AttendanceMap, guest: GuestModelNew): AttendanceMap => {
    acc[guest.name] = {attending: fillValue, ageGroup: null}
    return acc
  }, {})
}

export default class GuestAnswerForm extends React.Component<GuestAnswerFormProps, GuestAnswerFormState> {
  constructor(props: GuestAnswerFormProps) {
    super(props)
    const attendance = fill(props.guestParty.guests, true)
    this.state = {
      willAttend: '',
      dietary: {},
      hasDietary: '',
      attendingGuests: attendance,
      isSubmitting: false
    }
  }

  handleAttendanceChange = (_: React.ChangeEvent<unknown>, value: string): void => {
    this.setState({willAttend: value, hasDietary: ''})
  }

  submit = (): void => {
    this.setState({isSubmitting: true})
    let attendance: AttendanceMap
    if (this.isAttending()) {
      attendance = this.state.attendingGuests

    } else {
      attendance = fillEmpty(this.props.guestParty.guests, false)
    }

    const guests: GuestResponseModelNew[] = this.props.guestParty.guests.map(x => {
      return {
        id: x.id,
        attending: attendance[x.name].attending,
        dietary: this.state.dietary[x.name],
        ageGroup: attendance[x.name].ageGroup
      }
    })

    const model: GuestPartyResponseModelNew = {
      guests: guests
    }
    try {
      this.props.onSubmit(model)
    } finally {
      this.setState({isSubmitting: false})
    }
  }

  canSubmit = (): boolean => {
    if (this.isAttending()) {
      return this.hasCompletedDietary() &&
        this.hasAtLeastOneGuestAttending() &&
        this.hasAgeGroupForAllUnder18Guests()
    }
    return this.state.willAttend !== ''
  }

  hasAgeGroupForAllUnder18Guests = (): boolean => {
    // Get all guests that are under 18
    const under18Guests = this.props.guestParty.guests.filter(x => x.under18)
    // Map those to attending guests
    let result = true
    under18Guests.forEach(guest => {
      const attendance = this.state.attendingGuests[guest.name]
      result = attendance.ageGroup !== ''
    })
    // Check all matching attending guests have an age group
    return result
  }

  hasAtLeastOneGuestAttending = (): boolean => {
    return Object.values(this.state.attendingGuests).some(elem => elem)
  }

  hasCompletedDietary = (): boolean => {
    if (this.hasMadeSelectionForDietary()) {
      return this.hasDietaryRequirements() ? this.dietaryRequirementsAreNotEmpty() : true
    }
    return false
  }

  hasMadeSelectionForDietary = (): boolean => {
    return this.state.hasDietary !== ''
  }

  isAttending = (): boolean => {
    return this.state.willAttend === 'yes'
  }

  hasDietaryRequirements = (): boolean => {
    return this.state.hasDietary === 'yes'
  }

  dietaryRequirementsAreNotEmpty = (): boolean => {
    return Object.values(this.state.dietary).some(elem => elem.trim().length > 0)
  }

  updateAttendingGuests = (attendingGuests: AttendanceMap): void => {
    this.setState({
      attendingGuests
    })
  }

  hasMultipleGuests = (): boolean => {
    return this.props.guestParty.guests.length > 1
  }

  render(): React.ReactNode {
    const name = `Hi ${this.props.name}`
    if (this.props.guestParty.responded) {
      return (
        <Grid container direction='column'>
          <Grid item>
            <CursiveText align='center' variant='h4'>{name} <span role="img" aria-label="love letter">💌</span></CursiveText>
          </Grid>
          <Grid item>
            <Typography align="center">
              Thanks for responding! If you wanted to change something just let {' '}
              {this.props.brideGroom.groom} or {this.props.brideGroom.bride} know directly!
            </Typography>
          </Grid>
        </Grid>
      )
    }
    const attendingGuests = this.props.guestParty.guests.map(x => x.name).filter(name => this.state.attendingGuests[name])
    const dietaries = attendingGuests.map((guestName, idx) => (
      <div key={idx}>
        <TextField
          inputProps={{maxLength: 40}}
          id='vegetarians'
          value={this.state.dietary[guestName] || ''}
          onChange={(event) => this.setState({
            dietary: {...this.state.dietary, [guestName]: event.target.value},
          })
          }
          label={Display.capitalizeName(guestName)}
        />
      </div>
    ))
    return (
      <Grid container direction='column' spacing={1}>
        <Grid item>
          <CursiveText align='inherit' variant='h4'>{name} <span role="img" aria-label="love letter">💌</span></CursiveText>
        </Grid>
        <Grid item>
          <FormLabel>Will you be able to attend?</FormLabel>
        </Grid>
        <Grid item>
          <RadioGroup
            aria-label='attend'
            name='attend'
            value={this.state.willAttend}
            onChange={this.handleAttendanceChange}
            row
          >
            <FormControlLabel
              value='yes'
              control={<Radio color='primary' />}
              label="Wouldn't miss it"
            />
            <FormControlLabel
              value='no'
              control={<Radio color='primary' />}
              label='Celebrating from afar'
            />
          </RadioGroup>
        </Grid>
        {this.isAttending() && (
          <React.Fragment>
            {this.hasMultipleGuests() && (
              <Grid item>
                <GuestAttendanceForm
                  guests={this.props.guestParty.guests}
                  updateState={this.updateAttendingGuests}
                  state={this.state.attendingGuests}
                  showError={!this.hasAtLeastOneGuestAttending()} />
              </Grid>
            )}
            <Grid item>
              <FormLabel>Dietary requirements? </FormLabel>
              <RadioGroup
                aria-label='dietary requirements'
                name='dietary'
                value={this.state.hasDietary}
                onChange={(_: React.ChangeEvent<unknown>, value: string) => this.setState({hasDietary: value})}
                row
              >
                <FormControlLabel value='yes' control={<Radio color='primary' />} label='Yes' />
                <FormControlLabel value='no' control={<Radio color='primary' />} label='No' />
              </RadioGroup>
            </Grid>
          </React.Fragment>)
        }
        {this.hasDietaryRequirements() && dietaries}
        <Grid item>
          {this.state.isSubmitting ? <CircularProgress data-testid='circular-progress' />
            : <Button
              variant='contained'
              color='primary'
              onClick={this.submit}
              disabled={!this.canSubmit()}
              style={{marginTop: 20}}
              aria-label='rsvp-submit'
            >
              Send Response
            </Button>
          }
        </Grid>
      </Grid >
    )
  }
}
