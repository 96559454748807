import * as React from 'react'
import { Link } from '@material-ui/core'

interface NewTabLinkProps {
  href: string;
}

const NewTabLink: React.FunctionComponent<NewTabLinkProps> = (props): JSX.Element => {
  return (<Link href={props.href} variant='body2' color='secondary' underline="always" target="_blank">
    {props.children}
  </Link>)
}

export default NewTabLink
