import {
  Button,
  createStyles,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
  WithStyles,
  withStyles,
  IconButton,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import * as React from 'react'
import {GuestPartyModelNew, partialToWholeParty, EMPTY_GUEST, Address, partialToWholeAddress} from '../../model/NewGuestModel'
import {StyleRules} from '@material-ui/core/styles'
import Display from '../../service/Display'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import {BooleanMap} from '../../model/MapUtilTypes'

export interface GuestPartyModalFormProps extends WithStyles<typeof styles> {
  onClose: () => void;
  guest: GuestPartyModelNew;
  addGuest: (guest: GuestPartyModelNew) => Promise<void>;
  updateGuest: (guest: GuestPartyModelNew) => Promise<void>;
}
type GuestPartyModalModel = Pick<GuestPartyModelNew, 'id' | 'side' | 'guests' | 'address'>
type TextFieldEvent = React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>

interface GuestPartyModalFormState {
  guestParty: GuestPartyModalModel;
  add: boolean;
  requiredFieldNotSet: BooleanMap;
  commaSeparatedName: string;
  address: Address;
}
class GuestPartyModalFormInner extends React.Component<GuestPartyModalFormProps, GuestPartyModalFormState> {
  constructor(props: GuestPartyModalFormProps) {
    super(props)
    let names: string
    if (props.guest.guests.length > 0) {
      names = props.guest.guests.map(x => x.name).reduce((acc, cur) => `${acc}, ${cur}`)
    } else {
      names = ''
    }
    this.state = {
      guestParty: {
        id: props.guest.id,
        side: props.guest.side,
        guests: props.guest.guests,
      },
      add: props.guest.id === 0,
      requiredFieldNotSet: {},
      commaSeparatedName: names,
      address: props.guest.address
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
  }

  public handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = event.target.name
    const change = {[name]: value}
    const updatedGuest = {...this.state.guestParty, ...change}
    this.setState({
      guestParty: updatedGuest,
    })
  }

  public handleSelect(event: React.ChangeEvent<HTMLSelectElement>): void {
    const change = {side: event.target.value}
    const updatedGuest = {...this.state.guestParty, ...change}
    this.setState({
      guestParty: updatedGuest,
    })
  }

  public save = async (): Promise<void> => {
    const model = partialToWholeParty(this.state.guestParty)
    if (this.isNotValid(model)) {
      this.setState({requiredFieldNotSet: {...this.state.requiredFieldNotSet, name: true}})
      return
    }
    if (this.state.address) {
      model.address = partialToWholeAddress(this.state.address)
    }

    if (this.state.add) {
      await this.props.addGuest(model)
    } else {
      await this.props.updateGuest(model)
    }
    this.props.onClose()
  }

  public isNotValid = (model: GuestPartyModelNew): boolean => {
    return model.guests.some(x => {
      const nameIsNull = x.name == null
      const nameIsEmpty = x.name.length === 0
      const hasNoSurname = !x.name.trim().includes(" ")

      return nameIsNull || nameIsEmpty || hasNoSurname
    })
  }


  public handleNameChange = (idx: number) => (event: TextFieldEvent): void => {
    const guestParty = this.state.guestParty
    guestParty.guests[idx].name = event.target.value
    this.setState({
      guestParty
    })
  }

  public handleAddressChange = (key: string) => (event: TextFieldEvent): void => {
    const change = {[key]: event.target.value}
    const updatedAddress = {...this.state.address, ...change}
    this.setState({address: updatedAddress})
  }

  public addNewGuest = (): void => {
    const guestParty = this.state.guestParty
    if (guestParty.guests.length < 10) {
      guestParty.guests.push(EMPTY_GUEST())
      this.setState({
        guestParty
      })
    }
    // TODO: Give message saying max is 10 in one party and disable button
  }

  public removeGuest = (idx: number) => () => {
    const guestParty = this.state.guestParty
    guestParty.guests.splice(idx, 1)
    this.setState({
      guestParty
    })
  }

  public handleAgeChange = (idx: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const guestParty = this.state.guestParty
    guestParty.guests[idx].under18 = event.target.checked
    this.setState({
      guestParty
    })
  }

  public renderGuestNames = (): JSX.Element => {
    const guests = this.state.guestParty.guests.map((x, idx) => (
      <React.Fragment key={idx}>
        <Grid container spacing={0} alignItems='flex-end'>
          <Grid item xs={10}>
            <TextField
              key={idx}
              label='Name'
              name='name'
              required
              error={this.state.requiredFieldNotSet['name']}
              className={this.props.classes.textField}
              value={Display.capitalizeName(x.name)}
              onChange={this.handleNameChange(idx)}
            />
          </Grid>
          <Grid item xs={2}>
            <IconButton onClick={this.removeGuest(idx)} size='small'>
              <RemoveIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <FormControlLabel control={<Checkbox checked={x.under18} onChange={this.handleAgeChange(idx)} />} label='Under 18' />
          </Grid>
        </Grid>
      </React.Fragment>
    ))
    return (
      <Grid item xs={4}>
        {guests}
        <IconButton onClick={this.addNewGuest} size='small'>
          <AddIcon />
        </IconButton>
      </Grid>
    )
  }

  public render(): JSX.Element {
    const classes = this.props.classes
    const guestNames = this.renderGuestNames()
    const {guestParty, address} = this.state
    return (
      <div>
        <form className={classes.container} noValidate autoComplete='off'>
          <Grid container spacing={2}>
            {guestNames}
            <Grid item xs={2}>
              <FormControl>
                <InputLabel>Side</InputLabel>
                <Select
                  name='side'
                  className={classes.textField}
                  value={guestParty.side || 'None'}
                  onChange={this.handleSelect}
                  inputProps={{
                    name: 'side',
                    id: 'side',
                  }}
                >
                  <MenuItem value='None'>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value='Bride'>Bride</MenuItem>
                  <MenuItem value='Groom'>Groom</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    label="Street Address"
                    value={address ? address.streetAddress : ''}
                    onChange={this.handleAddressChange('streetAddress')} />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="City"
                    value={address ? address.city : ''}
                    onChange={this.handleAddressChange('city')} />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="State"
                    value={address ? address.state : ''}
                    onChange={this.handleAddressChange('state')} />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Post Code"
                    value={address ? address.postCode : ''}
                    onChange={this.handleAddressChange('postCode')} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={0} justify='flex-end'>
            <Grid item xs={2}>
              <Button variant='contained' color='secondary' onClick={this.save}>
                Save
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button variant='contained' onClick={this.props.onClose}>
                Close
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    )
  }
}

const styles = ({spacing}: Theme): StyleRules => createStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: spacing(),
    marginRight: spacing(),
  },
})

const GuestPartyModalForm = withStyles(styles)(GuestPartyModalFormInner)
export default GuestPartyModalForm
