import axios, { AxiosRequestConfig } from 'axios'
import WeditApi from '../../common/WeditApi'
import { GuestPartyModelNew } from '../model/NewGuestModel'

function buildConfig(token: string): AxiosRequestConfig {
  return { headers: { Authorization: `Bearer ${token}` } }
}

export default class GuestApi {
  public static URL = `${WeditApi.URL}/guests`

  public static async get(token: string): Promise<GuestPartyModelNew[]> {
    return await axios.get(`${this.URL}`, buildConfig(token)).then((x) => x.data)
  }

  public static async post(model: GuestPartyModelNew, token: string): Promise<void> {
    return await axios.post(`${this.URL}`, model, buildConfig(token))
  }

  public static async put(model: GuestPartyModelNew, token: string): Promise<void> {
    return await axios.put(`${this.URL}/${model.id}`, model, buildConfig(token))
  }

  public static async putGuestPartyAttendance(guestPartyId: number, model: UpdateGuestAttendanceModel, token: string): Promise<void> {
    return await axios.put(`${this.URL}/attendance/${guestPartyId}`, model, buildConfig(token))
  }

  public static async delete(id: number, token: string): Promise<void> {
    return await axios.delete(`${this.URL}/${id}`, buildConfig(token))
  }
}

export interface Attendance {
  [key: string]: boolean;
}


interface UpdateGuestAttendanceModel {
  attendance: Attendance;
}
