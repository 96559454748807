import React from "react"
import SettingsModel, { EMPTY_SETTINGS } from "./SettingsModel"

export interface ContextModel {
  settings: SettingsModel;
  token: string;
}

const EMPTY_CONTEXT = {
  settings: EMPTY_SETTINGS,
  token: ''
}

const AppContext: React.Context<ContextModel> = React.createContext(EMPTY_CONTEXT)

export default AppContext