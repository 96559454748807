import {Tab, Tabs, Grid, createStyles, withStyles, WithStyles, CircularProgress} from '@material-ui/core'
import * as React from 'react'
import GuestResponseApi, {GuestPartyResponseModelNew} from '../../organizerView/service/GuestResponseApi'
import GuestAnswer from './GuestAnswer'
import OurStory from './tabs/OurStory'
import Reception from './tabs/Reception'
import CountdownTimer from './CountdownTimer'
import Ceremony from './tabs/Ceremony'
import Memories from "./tabs/Memories"
import {GuestPartyModelNew} from '../../organizerView/model/NewGuestModel'
import AppContext from '../../settings/model/Context'
import ContentApi, {Content} from '../service/ContentApi'
import {StyleRules} from '@material-ui/core/styles'

export interface GuestContentContainerProps extends WithStyles<typeof styles> {
  guest: GuestPartyModelNew;
  hasResponded: () => void;
}
export interface GuestContentContainerState {
  value: number;
  content: Content | null;
  isLoading: boolean;
}

class GuestContentContainerInner
  extends React.Component<GuestContentContainerProps, GuestContentContainerState> {
  static contextType = AppContext
  public constructor(props: GuestContentContainerProps) {
    super(props)

    this.state = {
      value: 0,
      content: null,
      isLoading: true
    }
  }

  componentDidMount(): void {
    // TODO: Handle error case here
    ContentApi.getContent().then(x => this.setState({content: x, isLoading: false}))
  }

  public handleChange = (_: React.ChangeEvent, value: number): void => {
    this.setState({value})
  }

  public submitGuestAnswer = async (response: GuestPartyResponseModelNew, guestPartyId: number): Promise<void> => {
    await GuestResponseApi.putAttending(guestPartyId, response)
    this.props.hasResponded()
  }

  public render(): React.ReactNode {
    const {value, content} = this.state
    const separateCeremonyAndReception = this.context.settings.separateCeremonyAndReception

    let tabs
    let contentItems = null
    if (separateCeremonyAndReception) {
      tabs = (
        <Tabs value={this.state.value} onChange={this.handleChange}>
          <Tab label='Our Story' className={this.props.classes.tab} />
          <Tab label='Ceremony' className={this.props.classes.tab} />
          <Tab label='Reception' className={this.props.classes.tab} />
          <Tab label='Memories' className={this.props.classes.tab} />
          <Tab label='RSVP' className={this.props.classes.tab} />
        </Tabs>
      )
      if (!this.state.isLoading) {
        contentItems = (
          <Grid item>
            {value === 0 && <OurStory content={content.story} />}
            {value === 1 && <Ceremony content={content.ceremony} />}
            {value === 2 && <Reception content={content.reception} />}
            {value === 3 && <Memories content={content.memories} />}
            {value === 4 && <GuestAnswer submitGuestAnswer={this.submitGuestAnswer} guestParty={this.props.guest} />}
          </Grid>
        )
      }
    } else {
      tabs = (
        <Tabs value={this.state.value} onChange={this.handleChange}>
          <Tab label='Our Story' className={this.props.classes.tab} />
          <Tab label='Venue' className={this.props.classes.tab} />
          <Tab label='Memories' className={this.props.classes.tab} />
          <Tab label='RSVP' className={this.props.classes.tab} />
        </Tabs>
      )
      if (!this.state.isLoading) {
        contentItems = (
          <Grid item>
            {value === 0 && <OurStory content={content.story} />}
            {value === 1 && <Reception content={content.reception} />}
            {value === 2 && <Memories content={content.memories} />}
            {value === 3 && <GuestAnswer submitGuestAnswer={this.submitGuestAnswer} guestParty={this.props.guest} />}
          </Grid>
        )
      }
    }

    return (
      <React.Fragment>
        <CountdownTimer countDownDate={new Date(this.context.settings.weddingTime)} />
        <Grid container justify='center' alignItems='center' direction='column'>
          <Grid item>
            {tabs}
          </Grid>
          {this.state.isLoading ?
            <Grid item>
              <CircularProgress />
            </Grid>
            :
            contentItems}
        </Grid>
      </React.Fragment>
    )
  }
}

const styles = (): StyleRules => createStyles({
  tab: {
    background: '#edf0e06b'
  },
})

const GuestContentContainer = withStyles(styles)(GuestContentContainerInner)

export default GuestContentContainer
