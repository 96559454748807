import * as React from 'react'
import { Grid, TextField } from '@material-ui/core'
import SearchIcon from "@material-ui/icons/Search"

export interface SearchBarProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export function SearchBar(props: SearchBarProps): JSX.Element {
  return (
    <Grid container spacing={0}>
      <Grid item>
        <SearchIcon />
      </Grid>
      <Grid item>
        <TextField id="input-with-icon-grid" placeholder="Search..." onChange={props.onChange} />
      </Grid>
    </Grid>
  )
}
