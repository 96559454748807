import {MenuItem, Select} from '@material-ui/core'
import FilterListIcon from '@material-ui/icons/FilterList'
import * as React from 'react'

export interface MenuItem {
  value: string;
  text: string;
}
export interface FilterSelectProps {
  initialValue?: string;
  onChange: (newValue: string) => void;
  items: MenuItem[];
  displayEmpty: boolean;
}
interface FilterSelectState {
  value: string;
}

export default class FilterSelect extends React.Component<FilterSelectProps, FilterSelectState> {
  constructor(props: FilterSelectProps) {
    super(props)
    this.state = {
      value: props.initialValue || '',
    }
  }

  public onChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    this.props.onChange(event.target.value)
    this.setState({value: event.target.value})
  }

  public render(): JSX.Element {
    const items = this.props.items.map((x, idx) => (
      <MenuItem key={idx} value={x.value}>
        {x.text}
      </MenuItem>
    ))
    return (
      <React.Fragment>
        <Select
          value={this.state.value}
          IconComponent={FilterListIcon}
          displayEmpty={this.props.displayEmpty}
          onChange={this.onChange}
        >
          {items}
        </Select>
      </React.Fragment>
    )
  }
}
