import axios from 'axios'
import WeditApi from '../../common/WeditApi'

class GuestResponseApi {
  public static URL = `${WeditApi.URL}/guestResponse`

  public static async putAttending(
    guestId: number,
    responseModel: GuestPartyResponseModelNew,
  ): Promise<void> {
    await axios.put(`${this.URL}/${guestId}`, responseModel)
  }
}

export interface GuestPartyResponseModelNew {
  guests: GuestResponseModelNew[];
}

export interface GuestResponseModelNew {
  id: number;
  attending: boolean;
  dietary: string;
  ageGroup?: string;
}

export default GuestResponseApi
