import { Typography } from '@material-ui/core'
import { TypographyProps } from '@material-ui/core/Typography'
import * as React from 'react'

interface CursiveTextProps {
  variant: TypographyProps['variant'];
  align: TypographyProps['align'];
  className?: string;
}

const CursiveText: React.FunctionComponent<CursiveTextProps> = (props): JSX.Element => {
  const fontFamily = {
    fontFamily: ['Allura', 'Satisfy', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  }
  return (
    <Typography
      variant={props.variant}
      align={props.align}
      style={fontFamily}
      className={props.className}
    >
      {props.children}
    </Typography>
  )
}

export default CursiveText
