import {IconButton, TableCell, TableRow} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import * as React from 'react'
import NameCell from '../NameCell'
import TableTextCell from '../TableTextCell'
import {GuestPartyModelNew} from '../../model/NewGuestModel'
import Display from '../../service/Display'

export interface GuestRowProps {
  guestParty: GuestPartyModelNew;
  rowClick: (model: GuestPartyModelNew) => void;
  iconClick: (model: GuestPartyModelNew) => void;
}

const GuestRow: React.FunctionComponent<GuestRowProps> = (props): JSX.Element => {
  const editClick = (guest: GuestPartyModelNew): React.MouseEventHandler => (): void => {
    props.rowClick(guest)
  }
  const deleteClick = (guest: GuestPartyModelNew): React.MouseEventHandler => (event: React.MouseEvent): void => {
    event.stopPropagation()
    props.iconClick(guest)
  }

  return (
    <TableRow hover={true} onClick={editClick(props.guestParty)}>
      <NameCell names={props.guestParty.guests.map(x => Display.capitalizeName(x.name))} />
      <TableTextCell>{props.guestParty.side}</TableTextCell>
      <TableCell>
        <IconButton aria-label='Delete' onClick={deleteClick(props.guestParty)}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}
export default GuestRow
