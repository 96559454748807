import * as React from 'react'
import ModalMode from '../../model/ModalMode'
import GuestApi from '../../service/GuestApi'
import DeleteGuestModal from './DeleteGuestModal'
import GuestTableContainer from './GuestTableContainer'
import GuestPartyModal from './GuestPartyModal'
import {GuestPartyModelNew, EMPTY_PARTY} from '../../model/NewGuestModel'

export interface GuestPartyModalWrapperProps {
  guests: GuestPartyModelNew[];
  fetchGuests: () => void;
  token: string;
}
export interface GuestPartyModalWrapperState {
  showModal: ModalToShow | null;
  modalGuest: GuestPartyModelNew | null;
}

enum ModalToShow {
  AddEdit,
  Delete,
}

export default class GuestPartyModalWrapper extends React.Component<GuestPartyModalWrapperProps, GuestPartyModalWrapperState> {
  constructor(props: GuestPartyModalWrapperProps) {
    super(props)
    this.state = {showModal: null, modalGuest: null}
  }

  public showModal = (mode: ModalMode) => (person: GuestPartyModelNew) => {
    if (mode === ModalMode.Delete) {
      this.setState({showModal: ModalToShow.Delete, modalGuest: person})
      return
    }
    if (mode === ModalMode.Add) {
      person = EMPTY_PARTY()
    }
    this.setState({showModal: ModalToShow.AddEdit, modalGuest: person})
  }

  public hideModal = (): void => {
    this.setState({showModal: null})
  }

  public hideAndFetch = (): void => {
    this.hideModal()
    this.props.fetchGuests()
  }

  public deleteGuest = async (guest: GuestPartyModelNew): Promise<void> => {
    await GuestApi.delete(guest.id, this.props.token)
  }

  public addGuest = async (guest: GuestPartyModelNew): Promise<void> => {
    await GuestApi.post(guest, this.props.token)
  }

  public updateGuest = async (guest: GuestPartyModelNew): Promise<void> => {
    await GuestApi.put(guest, this.props.token)
  }

  public render(): JSX.Element {
    return (
      <React.Fragment>
        {this.state.modalGuest && (
          <React.Fragment>
            <GuestPartyModal
              show={this.state.showModal === ModalToShow.AddEdit}
              onClose={this.hideAndFetch}
              guestParty={this.state.modalGuest}
              addGuestParty={this.addGuest}
              updateGuestParty={this.updateGuest}
            />
            <DeleteGuestModal
              show={this.state.showModal === ModalToShow.Delete}
              onClose={this.hideAndFetch}
              guestParty={this.state.modalGuest}
              deleteGuestParty={this.deleteGuest}
            />
          </React.Fragment>
        )}
        <GuestTableContainer
          guests={this.props.guests}
          showModal={this.showModal}
          fetchGuests={this.props.fetchGuests}
        />
      </React.Fragment>
    )
  }
}
