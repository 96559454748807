import { TableRow } from '@material-ui/core'
import * as React from 'react'
import Display from '../../service/Display'
import NameCell from '../NameCell'
import TableTextCell from '../TableTextCell'
import { GuestPartyModelNew, GuestModelNew } from '../../model/NewGuestModel'

export interface InviteTableRowProps {
  guestParty: GuestPartyModelNew;
  onClick: (guest: GuestPartyModelNew) => void;
}

function attendingText(guests: GuestModelNew[]): string[] {
  if (guests.every(x => x.attending === null || x.attending === undefined)) {
    return ['-']
  } else if (guests.every(x => x.attending === true)) {
    return [`${Display.CHECK}`]
  } else if (guests.every(x => x.attending === false)) {
    return [`${Display.CROSS}`]
  } else {
    return guests.map(x => `${Display.booleanToSymbol(x.attending)} ${Display.capitalizeName(x.name)}`)
  }
}

export function InviteTableRow(props: InviteTableRowProps): JSX.Element {
  const dietaryText: string[] = props.guestParty.guests.filter(x => x.dietary).map(guest => `${Display.capitalizeName(guest.name)} - ${guest.dietary}`)
  return (
    <TableRow hover onClick={() => props.onClick(props.guestParty)}>
      <NameCell names={props.guestParty.guests.map(x => Display.capitalizeName(x.name))} />
      <TableTextCell>{Display.booleanToSymbol(props.guestParty.responded)}</TableTextCell>
      <NameCell names={attendingText(props.guestParty.guests)} />
      <NameCell names={dietaryText} />
    </TableRow>
  )
}
