import * as React from 'react'
import {
  createStyles, withStyles,
} from '@material-ui/core'
import {StyleRules, WithStyles} from '@material-ui/core/styles'


export interface AlertProps extends WithStyles<typeof styles> {
  heading?: string;
  children?: React.ReactNode;
}

function AlertInner(props: AlertProps): JSX.Element {
  return (
    <div className={props.classes.alert}>
      {props.heading && (<p className={props.classes.heading}>
        {props.heading}
      </p>
      )}
      <p>
        {props.children}
      </p>
    </div>
  )
}

const styles = (): StyleRules => createStyles({
  alert: {
    backgroundColor: ' #fff5f5',
    borderColor: '#f56565',
    color: '#c53030',
    borderTopWidth: '2px',
    borderBottomWidth: '2px',
    borderLeftWidth: '0px',
    borderRightWidth: '0px',
    borderStyle: 'solid',
    paddingLeft: '10px',
    paddingRight: '10px'
  },
  heading: {
    fontWeight: 700
  }
})

const Alert = withStyles(styles)(AlertInner)
export default Alert
