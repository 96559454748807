import * as React from 'react'
import PaperPage from '../../../common/components/PaperPage'
import { Typography, Grid, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core'
import CursiveText from '../../../common/components/CursiveText'
import DirectionsIcon from "@material-ui/icons/Directions"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import NewTabLink from '../../../common/components/NewTabLink'
import { ReceptionContent } from '../../service/ContentApi'
import ContentService from '../../../common/ContentService'

export interface ReceptionProps {
  content?: ReceptionContent;
}

function Reception(props: ReceptionProps): JSX.Element {
  return (
    <PaperPage style={{ padding: 20, maxWidth: "90%", marginLeft: "auto", marginRight: "auto" }}>
      <div style={{ textAlign: 'center' }}>
        <CursiveText variant='h4' align='center'>
          Reception
        </CursiveText>
        <br />
        <Typography variant="body2" align="center">
          {ContentService.parseContent(props.content.text)}
          <br />
        </Typography>
        <Typography variant='body1' paragraph>
          {props.content.venue.name}
          <br />
          {ContentService.parseContent(props.content.venue.address)}
          <br />
          {ContentService.parseContent(props.content.venue.eventTime)}
          <br />
          <NewTabLink href={props.content.venue.directionLink}>
            Take me there!
            <DirectionsIcon />
          </NewTabLink>
        </Typography>
      </div>

      <Grid container direction="column" alignItems="center">
        <Grid item >
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
            >
              <Typography align="center">Parking options</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                {props.content.venue.parking.text}
                <ul>
                  {props.content.venue.parking.locations.map((x, idx) => (
                    <li key={idx}>
                      <NewTabLink href={x.link}>
                        {x.address}
                        <DirectionsIcon />
                      </NewTabLink>
                    </li>
                  ))}
                </ul>
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Grid>
        <Grid item>

          <img src={props.content.imageData.src}
            alt={props.content.imageData.alt} />
        </Grid>
      </Grid>
    </PaperPage>
  )
}

export default Reception
