import * as React from 'react'
import ImageCarousel from '../ImageCarousel'
import { MemoriesContent } from '../../service/ContentApi'

export interface MemoriesProps {
  content?: MemoriesContent;
}

const Memories: React.FunctionComponent<MemoriesProps> = (props): JSX.Element => {
  return (
    <ImageCarousel images={props.content.images} />
  )
}

export default Memories