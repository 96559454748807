import {
  createStyles, Paper, Theme, withStyles, WithStyles,
} from '@material-ui/core'
import * as React from 'react'
import {StyleRules} from '@material-ui/core/styles'

export interface PaperPageProps extends WithStyles<typeof styles> {
  style?: React.CSSProperties;
}

class PaperPageInner extends React.Component<PaperPageProps, Record<string, never>> {
  public render(): JSX.Element {
    return (
      <Paper className={this.props.classes.root} style={this.props.style}>
        {this.props.children}
      </Paper>
    )
  }
}

const styles = (theme: Theme): StyleRules => createStyles({
  root: {
    marginTop: theme.spacing(),
  },
})
const PaperPage = withStyles(styles)(PaperPageInner)

export default PaperPage
