import * as React from 'react'
import { Dialog, DialogTitle, DialogContentText } from '@material-ui/core'

interface FailureDialogProps {
  open: boolean;
  onClose: () => void;
}

const FailureDialog: React.FunctionComponent<FailureDialogProps> = (props): JSX.Element => {
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>We had a problem submitting your RSVP</DialogTitle>
      <DialogContentText>
              Something not quite right happened whilst we were trying to submit your RSVP.
              Please close this and try submitting again.
      </DialogContentText>
    </Dialog>
  )
}

export default FailureDialog
