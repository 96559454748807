import * as React from 'react'
import AddButton from '../../../common/components/AddButton'
import ModalMode from '../../model/ModalMode'
import GuestListTable from './GuestListTable'
import {TableControls} from '../../../common/components/TableControls'
import {GuestPartyModelNew} from '../../model/NewGuestModel'

interface GuestTableContainerProps {
  guests: GuestPartyModelNew[];
  showModal: (mode: ModalMode) => (person: any) => void;
  fetchGuests: () => void;
}

interface GuestTableContainerState {
  currentFilter: (guest: GuestPartyModelNew) => boolean;
  searchTerm: string;
}

interface Filters {
  [key: string]: (guest: GuestPartyModelNew) => boolean;
}

const filters: Filters = {
  '': () => true,
  'bride': (guest) => (guest.side ? guest.side.toLowerCase() === 'bride' : false),
  'groom': (guest) => (guest.side ? guest.side.toLowerCase() === 'groom' : false),
}

class GuestTableContainer extends React.Component<GuestTableContainerProps, GuestTableContainerState> {
  constructor(props: GuestTableContainerProps) {
    super(props)
    this.state = {
      currentFilter: filters[''],
      searchTerm: ''
    }
  }

  public handleSelect = (selectedSide: string): void => {
    this.setState({currentFilter: filters[selectedSide]})
  }

  public search = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({searchTerm: e.target.value})
  }

  public render(): JSX.Element {
    const filteredGuests = this.props.guests.filter(this.state.currentFilter)
    const guestsToShow = filteredGuests.filter(guest => guest.guests.some(guest => guest.name.includes(this.state.searchTerm)))
    return (
      <React.Fragment>
        <TableControls filters={[
          {text: 'No Filter', value: ''},
          {text: 'Bride\'s side', value: 'bride'},
          {text: 'Groom\'s side', value: 'groom'},
        ]} onFilterChange={this.handleSelect} onRefreshClick={this.props.fetchGuests} onSearchTermChange={this.search} />
        <GuestListTable
          guestParties={guestsToShow}
          rowClick={this.props.showModal(ModalMode.Edit)}
          iconClick={this.props.showModal(ModalMode.Delete)}
          headers={[
            'Name',
            'Side',
            <AddButton key='add' onClick={this.props.showModal(ModalMode.Add)} />,
          ]}
        />
      </React.Fragment>
    )
  }
}

export default GuestTableContainer
