import {Button} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import * as React from 'react'

interface AddButtonProps {
  onClick: React.MouseEventHandler;
}

const AddButton: React.FunctionComponent<AddButtonProps> = (props): JSX.Element => (
  <Button variant='contained' color='primary' aria-label='Add' onClick={props.onClick}>
    <AddIcon />
  </Button>
)

export default AddButton
