import * as React from 'react'
import { Snackbar, Theme, createStyles, SnackbarContent } from '@material-ui/core'
import { StyleRules, WithStyles, withStyles } from '@material-ui/core/styles'

interface ErrorSnackbarProps extends WithStyles<typeof styles> {
  message: string;
  open: boolean;
  onClose: () => void;
}

const ErrorSnackbarInner: React.FunctionComponent<ErrorSnackbarProps> = (props): JSX.Element => {
  return (
    <Snackbar
      open={props.open}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      autoHideDuration={6000}
      onClose={props.onClose}
    >
      <SnackbarContent
        message={props.message}
        className={props.classes.error}
      />
    </Snackbar>
  )
}

const styles = (theme: Theme): StyleRules => createStyles({
  error: {
    backgroundColor: theme.palette.error.dark,
  }
})
const ErrorSnackbar = withStyles(styles)(ErrorSnackbarInner)
export default ErrorSnackbar
