import * as React from 'react'
import {Redirect} from 'react-router'
import LoginApi from '../LoginApi'
import LoginLanding, {GuestName} from './LoginLanding'
import {GuestPartyModelNew} from '../../organizerView/model/NewGuestModel'
import {EmptyRecord} from '../../common/EmptyRecord'

export enum LoginState {
  NotLoggedIn,
  BrideGroom,
  Guest,
}

export interface LoginContainerProps {
  loginState: LoginState;
  updateLoginState: (newState: LoginState, token: string, guest: GuestPartyModelNew | null) => void;
  isAdmin: boolean;
}

export default class LoginContainer extends React.Component<LoginContainerProps, EmptyRecord> {
  public loginAsBrideGroom = async (user: string, pass: string): Promise<void> => {
    try {
      const response = await LoginApi.postLogin(user, pass)
      if (response.status === 200) {
        this.props.updateLoginState(LoginState.BrideGroom, response.data.access_token, null)
      } else {
        throw new Error('Wrong')
      }
    } catch (e) {
      throw e
    }
  }

  public loginAsGuest = async (guestName: GuestName): Promise<void> => {
    const loggedInGuest = await LoginApi.postGuestLogin(guestName)
    if (loggedInGuest) {
      this.props.updateLoginState(LoginState.Guest, '', loggedInGuest)
    }
  }

  public render(): React.ReactNode {
    const login = this.props.loginState
    if (login === LoginState.NotLoggedIn) {
      return (
        <LoginLanding brideGroomLogin={this.loginAsBrideGroom} guestLogin={this.loginAsGuest} isBrideGroom={this.props.isAdmin} />
      )
    }
    if (login === LoginState.Guest) {
      return <Redirect to='/guest' />
    }
    if (login === LoginState.BrideGroom) {
      return <Redirect to='/guestList' />
    }
    return null
  }
}
