import * as React from 'react'
import { Button, Grid, Typography } from '@material-ui/core'
import AppContext from '../../../settings/model/Context'


export interface ReportElementProps {
  buttonText: string;
  infoText: string;
  reportUrl: string;
  reportFileName: string;
}



export function ReportElement (props:  ReportElementProps): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const onClick = (token: string) => (): void => {
    const anchor = document.createElement("a")
    document.body.appendChild(anchor)
    const file = props.reportUrl
    const headers = new Headers()
    headers.append('Authorization', `Bearer ${token}`)

    fetch(file, { headers })
      .then(response => response.blob())
      .then(blob => {
        const objectUrl = window.URL.createObjectURL(blob)

        anchor.href = objectUrl
        anchor.download = props.reportFileName
        anchor.click()

        window.URL.revokeObjectURL(objectUrl)
      })
  }
  return (
    <AppContext.Consumer>
      {app => (
        <React.Fragment>
          <Grid item xs={3}>
            <Button variant="contained" onClick={onClick(app.token)}>
              {props.buttonText}
            </Button>
          </Grid>
          <Grid item xs={9}>
            <Typography>{props.infoText}</Typography>
          </Grid>
        </React.Fragment>

      )}
    </AppContext.Consumer>
  )
}
