import * as React from 'react'
import PaginatedTable from '../../../common/components/PaginatedTable'
import PaperPage from '../../../common/components/PaperPage'
import GuestRow from './GuestRow'
import {GuestPartyModelNew} from '../../model/NewGuestModel'

export interface GuestListTableProps {
  guestParties: GuestPartyModelNew[];
  rowClick: (model: GuestPartyModelNew) => void;
  iconClick: (model: GuestPartyModelNew) => void;
  headers: React.ReactNode[];
}

const GuestListTable: React.FunctionComponent<GuestListTableProps> = (props): JSX.Element => (
  <PaperPage>
    <PaginatedTable
      headers={props.headers}
      rowData={props.guestParties}
      rowRender={(guest) => (
        <GuestRow
          key={guest.id}
          guestParty={guest}
          rowClick={props.rowClick}
          iconClick={props.iconClick}
        />
      )}
    />
  </PaperPage>
)

export default GuestListTable
