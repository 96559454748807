export async function retry<T>(f: () => Promise<T>): Promise<T> {
  const maxRetries = 3
  let retries = 0
  while (retries < maxRetries) {
    try {
      return await f()
    } catch (err) {
      if (retries < maxRetries) {
        retries++
        await sleep(retries * 400)
      } else {
        throw err
      }
    }
  }
}

function sleep(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms))
}
