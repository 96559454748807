import {
  Button, MenuItem, Select, Typography, TextField, Grid
} from '@material-ui/core'
import * as React from 'react'
import BaseModal from '../../../common/components/BaseModal'
import Range from '../../service/Range'
import {GuestModelNew} from '../../model/NewGuestModel'
import Display from '../../service/Display'

export interface AssignTableModalProps {
  show: boolean;
  close: () => void;
  maxNumber: number;
  onSubmit: (tableNumber: number, seatNumber: number) => Promise<void>;
  guest: GuestModelNew | undefined;
}

interface AssignTableModalState {
  tableNumber: string;
  seatNumber: string;
}

export default class AssignTableModal extends React.Component<
AssignTableModalProps,
AssignTableModalState
> {
  constructor(props: AssignTableModalProps) {
    super(props)

    this.state = {
      tableNumber: '0',
      seatNumber: '1'
    }
  }

  componentDidUpdate(prevProps: AssignTableModalProps, _: AssignTableModalState): void {
    const prevGuest = prevProps.guest || {id: 0}
    if (this.props.guest.id !== prevGuest.id) {
      const tableNumber = this.props.guest.tableNumber || 0
      const seatNumber = this.props.guest.seatNumber || 1
      this.setState({tableNumber: tableNumber.toString(), seatNumber: seatNumber.toString()})
    }
  }

  public submit = (): void => {
    this.props.onSubmit(Number(this.state.tableNumber), Number(this.state.seatNumber)).then(() => this.props.close())
  }

  public onChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    this.setState({tableNumber: event.target.value})
  }

  public onSeatChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({seatNumber: event.target.value})
  }

  public render(): JSX.Element {
    if (!this.props.guest) {
      return null
    }

    const items = Range.to(this.props.maxNumber).map((x): JSX.Element => {
      if (x === 0) {
        return (
          <MenuItem key={x} value='0'>
            Bridal Table
          </MenuItem>
        )
      }
      const text = `Table ${x}`
      return (
        <MenuItem key={x} value={x}>
          {text}
        </MenuItem>
      )
    })
    return (
      <BaseModal show={this.props.show} onClose={this.props.close}>
        <Grid container alignItems='flex-end' spacing={1}>
          <Grid item xs={12}>
            <Typography>Which table should we put {Display.capitalizeName(this.props.guest.name)} on?</Typography>
          </Grid>
          <Grid item xs={4}>
            <Select value={this.state.tableNumber} onChange={this.onChange}>
              {items}
            </Select>
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Seat Number"
              value={this.state.seatNumber}
              onChange={this.onSeatChange}
              type="number"
              inputProps={{
                min: 1,
                max: 20
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Button variant='contained' onClick={this.submit}>
              Move
            </Button>
          </Grid>
        </Grid>
      </BaseModal>
    )
  }
}
