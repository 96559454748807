import Axios, {AxiosResponse} from '../../node_modules/axios'
import WeditApi from '../common/WeditApi'
import {GuestName} from './components/LoginLanding'
import {GuestPartyModelNew} from '../organizerView/model/NewGuestModel'

export default class LoginApi {
  public static URL = `${WeditApi.URL}/login`

  public static async postLogin(username: string, password: string): Promise<AxiosResponse> {
    return await Axios.post(`${this.URL}`, {username, password})
  }

  public static async postGuestLogin(name: GuestName): Promise<GuestPartyModelNew> {
    const body = {...name, clientUuid: WeditApi.CLIENT_UUID}
    return await Axios.post(`${this.URL}/guest`, body).then((x) => x.data)
  }
}
