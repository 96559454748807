import {CircularProgress, Tab, Tabs} from '@material-ui/core'
import * as React from 'react'
import ErrorPage from '../../common/components/ErrorPage'
import GuestApi from '../service/GuestApi'
import {GuestListTabContent} from './GuestListTabContent'
import {GuestPartyModelNew} from '../model/NewGuestModel'

interface GuestListTabContainerProps {
  token: string;
}

interface GuestListTabContainerState {
  value: number;
  hasError: boolean;
  isLoading: boolean;
  guests: GuestPartyModelNew[];
}

export default class GuestListTabContainer extends React.Component<GuestListTabContainerProps, GuestListTabContainerState> {
  constructor(props: GuestListTabContainerProps) {
    super(props)
    this.state = {
      value: 0,
      hasError: false,
      isLoading: false,
      guests: [],
    }
  }

  componentDidMount(): void {
    this.fetchGuestsWithLoader()
  }

  public handleChange = (_: unknown, value: number): void => {
    this.setState({value})
  }

  public fetchGuests = async (): Promise<void> => {
    try {
      const guests: GuestPartyModelNew[] = await GuestApi.get(this.props.token)
      this.setState({guests})
    } catch {
      this.setState({hasError: true})
    }
  }

  public async fetchGuestsWithLoader(): Promise<void> {
    this.setState({isLoading: true})
    await this.fetchGuests()
    this.setState({isLoading: false})
  }

  public render(): JSX.Element {
    if (this.state.hasError) {
      return <ErrorPage />
    }
    return (
      <React.Fragment>
        <Tabs value={this.state.value} onChange={this.handleChange} centered>
          <Tab label='Summary' />
          <Tab label='List' />
          <Tab label='Invites' />
          <Tab label='Tables' />
          <Tab label='Reports' />
        </Tabs>
        {this.state.isLoading ? (
          <CircularProgress size={100} />
        ) :
          (<GuestListTabContent guests={this.state.guests} tabValue={this.state.value} fetchGuests={this.fetchGuests} token={this.props.token} />)}
      </React.Fragment>
    )
  }
}
