import { ListItem, ListItemText, Typography } from '@material-ui/core'
import * as React from 'react'
import { GuestModelNew } from '../../model/NewGuestModel'
import Display from '../../service/Display'

interface TableGuestProps {
  guest: GuestModelNew;
  onClick: (guest: GuestModelNew) => void;
}

const TableGuest: React.FunctionComponent<TableGuestProps> = (props): JSX.Element => {
  let seatNumberText
  if (props.guest.seatNumber) {
    seatNumberText = `${props.guest.seatNumber} - `
  } else {
    seatNumberText = ''
  }
  return (
    <ListItem
      button
      dense
      onClick={() => {
        props.onClick(props.guest)
      }}
    >
      <ListItemText>
        <Typography>{seatNumberText}{Display.capitalizeName(props.guest.name)}</Typography>
      </ListItemText>
    </ListItem>
  )}

export default TableGuest
