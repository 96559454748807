import * as React from 'react'
import {FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox, FormHelperText, Select, MenuItem, InputLabel} from '@material-ui/core'
import Display from '../../../organizerView/service/Display'
import {GuestModelNew} from '../../../organizerView/model/NewGuestModel'
import {EmptyRecord} from '../../../common/EmptyRecord'


export interface GuestAttendanceFormProps {
  guests: GuestModelNew[];
  updateState: (attending: AttendanceMap) => void;
  state: AttendanceMap;
  showError: boolean;
}

export interface AttendanceMap {
  [key: string]: Attendance;
}

export interface Attendance {
  attending: boolean;
  ageGroup: string;
}

export default class GuestAttendanceForm extends React.Component<GuestAttendanceFormProps, EmptyRecord> {

  handleAttendingChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const attendance = this.props.state
    attendance[e.target.value].attending = e.target.checked
    this.props.updateState(attendance)
  }

  handleChange = (name: string) => (event: React.ChangeEvent<{value: string}>) => {
    const attendance = this.props.state
    attendance[name].ageGroup = event.target.value
    this.props.updateState(attendance)
  }

  render(): React.ReactNode {
    const {state} = this.props

    const content = this.props.guests.map((guest, idx) => {
      const name = guest.name
      return (
        <FormGroup key={idx} row={true} >
          <FormControlLabel
            control={<Checkbox checked={state[name].attending} onChange={this.handleAttendingChange} value={name} />}
            label={Display.capitalizeName(name)}
          />
          {guest.under18 &&
            <FormControl data-testid="age-form">
              <InputLabel id={`age-label-${idx}`}>Age</InputLabel>
              <Select
                id={`age-${guest.name}`}
                labelId={`age-label-${idx}`}
                value={state[name].ageGroup}
                onChange={this.handleChange(name)}
                autoWidth
              >
                {/* TODO: This will likely need to be part of settings */}
                <MenuItem value="YOUTH">Youth (12-18 years)</MenuItem>
                <MenuItem value="CHILD">Child (3-11 years)</MenuItem>
                <MenuItem value="INFANT">Infant (0-3 years)</MenuItem>
                {/* <MenuItem value="ADULT">Adult (18+ years)</MenuItem> */}
              </Select>
            </FormControl>}
        </FormGroup>)
    })
    return (
      <FormControl error={this.props.showError}>
        <FormLabel>Choose everyone who can make it</FormLabel>
        <FormHelperText>If you all can make it just leave them all ticked</FormHelperText>
        <FormGroup>
          {content}
        </FormGroup>
        {this.props.showError && <FormHelperText>If no one can attend please change your selection above</FormHelperText>}
      </FormControl>
    )
  }
}
