import * as React from 'react'
import PaperPage from '../../common/components/PaperPage'
import {GuestPartyResponseModelNew} from '../../organizerView/service/GuestResponseApi'
import GuestAnswerForm from './rsvpForm/GuestAnswerForm'
import {GuestPartyModelNew} from '../../organizerView/model/NewGuestModel'
import AppContext from '../../settings/model/Context'
import Display from '../../organizerView/service/Display'
import FailureDialog from './rsvpForm/FailureDialog'

export interface GuestAnswerProps {
  guestParty: GuestPartyModelNew;
  submitGuestAnswer: (response: GuestPartyResponseModelNew, guestPartyId: number) => Promise<void>;
}

interface GuestAnswerState {
  canAttend: boolean;
  submitted: boolean;
  hasError: boolean;
}

export default class GuestAnswer extends React.Component<GuestAnswerProps, GuestAnswerState> {
  constructor(props: GuestAnswerProps) {
    super(props)
    this.state = {
      canAttend: false,
      submitted: false,
      hasError: false
    }
  }

  static contextType = AppContext;

  public submit = async (response: GuestPartyResponseModelNew): Promise<void> => {
    try {
      await this.props.submitGuestAnswer(response, this.props.guestParty.id)
      this.setState({submitted: true, canAttend: response.guests.some(x => x.attending)})
    } catch (e) {
      this.setState({hasError: true})
    }
  }

  public firstName(name: string): string {
    return name.split(' ')[0]
  }

  public formatNameList(names: string[]): string {
    let result = ''
    const length = names.length
    names.forEach((elem, idx) => {
      const name = this.firstName(elem)
      if (idx === 0) {
        result = name
      } else if (idx === length - 1) {
        result = `${result} & ${name}`
      } else {
        result = `${result}, ${name}`
      }
    })
    return result
  }

  public render(): React.ReactNode {
    const formattedName = this.formatNameList(this.props.guestParty.guests.map(x => Display.capitalizeName(x.name)))
    // TODO: Consider if want to block second submission here
    return (
      <PaperPage style={{padding: 20, maxWidth: "90%", marginLeft: "auto", marginRight: "auto"}}>

        <GuestAnswerForm
          name={formattedName}
          guestParty={this.props.guestParty}
          onSubmit={this.submit}
          brideGroom={this.context.settings.names}
        />

        <FailureDialog open={this.state.hasError} onClose={() => this.setState({hasError: false})} />
      </PaperPage>
    )
  }
}
