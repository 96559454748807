import * as React from 'react'
import {CircularProgress, Backdrop} from '@material-ui/core'


const AppLoading: React.FunctionComponent<Record<string, never>> = (): JSX.Element => {
  return (
    <Backdrop open>
      <CircularProgress size={100} />
    </Backdrop >
  )
}

export default AppLoading
