import * as React from 'react'
import MultiLineText from '../../common/components/MultiLineText'
import { TableCell } from '@material-ui/core'

interface NameCellProps {
  names: string[];
}

const NameCell: React.FunctionComponent<NameCellProps> = (props): JSX.Element => (
  <TableCell>
    <MultiLineText textLines={props.names} />
  </TableCell>
)

export default NameCell
