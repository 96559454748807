import axios, { AxiosRequestConfig } from 'axios'
import WeditApi from '../../common/WeditApi'
import GuestStatistic from '../model/GuestStatistic'
import { GuestModelNew } from '../model/NewGuestModel'

function buildConfig(token: string): AxiosRequestConfig {
  return { headers: { Authorization: `Bearer ${token}` } }
}

export interface TableGuests {
  [key: number]: GuestModelNew[];
}

export interface TableUpdateRequest {
  guestId: number;
  tableNumber: number;
  seatNumber: number;
}

export default class AuthenticatedApi {
  public static URL = `${WeditApi.URL}`

  public static async getGuestStats(token: string): Promise<GuestStatistic[]> {
    return await axios.get(`${this.URL}/guestStatistics`, buildConfig(token)).then((x) => x.data.stats)
  }

  public static async getTableGuests(token: string): Promise<TableGuests> {
    return await axios.get(`${this.URL}/tables`, buildConfig(token)).then(x => x.data)
  }

  public static async updateGuestTableNumber(updateRequest: TableUpdateRequest, token: string): Promise<TableGuests> {
    return await axios.put(`${this.URL}/tables`, updateRequest, buildConfig(token)).then(x => x.data)
  }
}
