import * as React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"
import {Carousel} from 'react-responsive-carousel'
import {CircularProgress} from '@material-ui/core'
import PaperPage from '../../common/components/PaperPage'
import CursiveText from '../../common/components/CursiveText'
import {ImageData} from '../service/ContentApi'

export interface ImageCarouselProps {
  images: ImageData[];
}
interface ImageCarouselState {
  imagesLoaded: number;
}
class ImageCarousel extends React.Component<ImageCarouselProps, ImageCarouselState> {
  constructor(props: ImageCarouselProps) {
    super(props)
    this.state = {
      imagesLoaded: 0
    }
  }
  startIndex(max: number): number {
    return Math.floor((Math.random() * max) + 1)
  }
  public onImageLoad = (): void => {
    this.setState({imagesLoaded: this.state.imagesLoaded + 1})
  }

  render(): JSX.Element {
    const {images} = this.props
    const numberImages = images.length
    const imgs = images.map((x, idx) => {
      return (<div key={idx}>
        <img src={x.src} alt={x.alt} onLoad={this.onImageLoad} />
      </div>)
    })
    if (this.state.imagesLoaded < numberImages) {
      return (
        <React.Fragment>
          <div style={{display: "none"}}>{imgs}</div>
          <CursiveText align="center" variant="h4">This may take a moment, we have plenty of memories to share!</CursiveText>
          <CircularProgress size={100} />
        </React.Fragment>
      )
    }

    return (
      <PaperPage>
        <Carousel
          dynamicHeight
          autoPlay
          infiniteLoop
          showThumbs={false}
          showStatus={false}
          width="80vh"
          showIndicators={false}
          selectedItem={this.startIndex(numberImages)}>
          {imgs}
        </Carousel>
      </PaperPage>)
  }
}

export default ImageCarousel
